<script lang="ts">
  import { writable } from 'svelte/store';
  import Icon from '../icon/icon.svelte';

  export let content: string;
  export let label: string;

  const copied = writable(false);

  const copy = async (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(content);
      copied.set(true);
      setTimeout(() => {
        copied.set(false);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };
</script>

<button
  on:click={copy}
  class="copy-button"
  class:copied={$copied}
  aria-label={label}
>
  <Icon name={$copied ? 'checkmark' : 'copy'} />
</button>

<style lang="postcss">
  .copy-button {
    @apply appearance-none bg-transparent text-white hover:text-indigo-100 rounded-2xl border-2 border-transparent;

    &.copied {
      @apply text-mint border-mint;
    }
  }
</style>
